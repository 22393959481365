import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Involvement,
  Visit,
  TitleContent,
  Youtube,
  ExternalLink,
  WorkedWith,
} from "../components/parts"

const DearPost = () => (
  <Layout title="Dearpost">
    <Title heading="Dearpost" sub="Send your photos as real postcards" />
    <Overview
      project="dearpost"
      text={[
        "Dearpost helps anyone to snap a photo or Instagram photo and send it as a real postcard anywhere in the world.",
        "I built and oversaw the web app and onboarding artists to the platform.",
      ]}
    />
    <Visit label="Press coverage" project="dearpost" />
    <TitleContent title="Postcards sent via the platform">
      <Row>
        {[1, 2, 3, 4, 5, 6].map(i => (
          <Col key={i} lg={6} md={6} xs={12} sm={12}>
            <img
              src={`/images/dearpost/${i}.jpg`}
              alt="postcard"
              style={{ marginBottom: 30 }}
            />
          </Col>
        ))}
      </Row>
    </TitleContent>
    <TitleContent title="Postcards from Indian artists">
      <Row>
        {[12, 8, 7, 9, 10, 11].map(i => (
          <Col key={i} lg={6} md={6} xs={12} sm={12}>
            <img
              src={`/images/dearpost/${i}.jpg`}
              alt="postcard"
              style={{ marginBottom: 30 }}
            />
          </Col>
        ))}
      </Row>
    </TitleContent>
    <TitleContent title="Advertisement">
      <Youtube src="https://www.youtube.com/embed/1hlZJKoqOKs" />
    </TitleContent>
    <TitleContent title="Visit">
      <ExternalLink
        href="https://www.instagram.com/dearpost.in/"
        label="Instagram"
      />
    </TitleContent>
    <Toolset project="dearpost" />
    <Involvement project="dearpost" />
    <WorkedWith project="dearpost" />
  </Layout>
)

export default DearPost
